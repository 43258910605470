const caseStudy = {
    solarBlocks: {
        title: 'Innovating Energy Investing - Blockchain Web3',
        description: 'Our client is dedicated to harnessing the power of blockchain and decentralized applications to reshape the way solar energy investments are made.',
        slug: '/case-studies/innovating-energy-investing',
        showSeeMoreButton: false,
        imgSrc: '/solar-blocks-mock.jpg',
        imgAlt: 'Innovating Energy Investing - Blockchain Web3',
        accordionList: [
            {
                title: 'Revolutionizing Investment Opportunities',
                body: 'Our client empowers innovative investment models by leveraging technology to connect NFTs with real-world assets. This approach enables individuals to invest in renewable energy with tangible benefits.'
            },
            {
                title: 'Generating Sustainable Revenue',
                body: 'Our solution allows investors to earn passive income by participating in clean energy production. Our engineering team facilitates the seamless integration of energy generation and investment, ensuring profitability for our clients.'
            },
            {
                title: 'Driving Sustainable Change',
                body: 'By enabling investments in solar energy, we help our clients contribute to a greener future while enjoying financial returns.'
            }
        ]
    },
    enhancedTravel: {
        title: 'Working together with a Leading Travel Services Company',
        description: 'Our client, a major name in holiday and travel services, sought to boost their software team to meet rising demands. Salspot Software stepped in to integrate seamlessly, ensuring rapid deployment and robust solutions without disrupting their established workflows.',
        slug: '/case-studies/enhancing-travel-experiences',
        showSeeMoreButton: false,
        imgSrc: '/mockup-holiday-availability-full.jpg',
        imgAlt: 'Enhancing Travel Experiences',
        accordionList: [
            {
                title: 'Challenge: Expanding Software Resources Quickly',
                body: 'With growing demand, our client needed skilled developers who could adapt smoothly into their team and processes. Our goal was to integrate seamlessly into their team, adapting to their processes and work culture.',
            },
            {
                title: 'Solution: Embedded Team and Agile Project Management',
                body: 'Salspot Software embedded developers directly into the client’s agile workflows, adapting to their internal processes and communication channels. This close collaboration enabled allowed us to provide solutions efficiently without disrupting the client’s established routines.'
            },
            {
                title: 'Outcome: Enhanced Service Quality and Reduced Time-to-Market',
                body: 'Our involvement helped accelerate feature rollouts and maintain high service quality, enhancing the user experience.'
            },
            // {
            //     title: 'Aggregating service suppliers',
            //     body: 'We enable seamless integration for a wide range of third party suppliers: car parking, transfers, hotels, and more in one place.',
            // }
        ]
    },
    fsm: {
        title: 'Optimizing Software Solutions for a Field Service Management Leader',
        description: 'We supported the transformation of a field service management system, enabling better performance across all platforms. Our solutions empowered technicians with real-time data and improved responsiveness for enhanced service quality.',
        slug: '/case-studies/fsm-solution',
        showSeeMoreButton: false,
        imgSrc: '/fsm-mockup.jpg',
        imgAlt: 'Dora ai Translator',
        accordionList: [
            {
                title: 'Challenge: Meeting Complex Field Service Demands',
                body: 'The client required additional technical expertise to address the complex needs of field service management, including real-time data handling and system integration.'
            },
            {
                title: 'Solution: Enhanced Performance through Read Model Architecture',
                body: 'Our team implemented a read model architecture, designed to boost performance by streamlining data retrieval and optimizing query responses within their platform.'
            },
            {
                title: 'Outcome: Increased System Efficiency and Reliability',
                body: 'With the new architecture in place, the system’s overall performance and responsiveness improved, providing a faster and more reliable experience for end-users.'
            }
        ]
    },
    emila: {
        title: 'Scaling On-Demand Service for the Modern Customer',
        description: 'Our client, a top service marketplace, sought a complete overhaul of their platform to improve performance and scalability. Salspot Software integrated with their team, delivering a full backend and frontend rewrite to elevate user experience and system efficiency.',
        slug: '/case-studies/on-demand-service-platform',
        showSeeMoreButton: false,
        imgSrc: '/crowdsource-fsm.jpg',
        imgAlt: 'On demand service platform',
        accordionList: [
            {
                title: 'Challenge: Addressing Scalability and User Experience Needs',
                body: 'The client needed a robust solution to handle rising user demands and improve the overall performance and reliability of their platform.'
            },
            {
                title: 'Solution: Comprehensive Backend and Frontend Redesign',
                body: 'Our joined team undertook a complete rewrite of both backend and frontend components, optimizing architecture to support scalability and faster, smoother operations.'
            },
            {
                title: 'Outcome: Enhanced Platform Performance and User Satisfaction',
                body: 'The new solution significantly boosted system performance and reliability, resulting in a seamless experience for both users and service providers.'
            }
        ]
    },
    iControl: {
        title: 'Smart Home Management',
        description: 'We partnered with a leading smart home platform to enhance user experience and system reliability. Our solutions improved device integration, making it easier for customers to manage their home security and automation with confidence and convenience.',
        slug: '/case-studies/smart-home-management',
        showSeeMoreButton: false,
        imgSrc: '/smart-home-management-3.jpg',
        imgAlt: 'Smart Home Management',
        accordionList: [
            {
                title: 'Enhancing Smart Home Solutions',
                body: 'We worked on improving a smart home platform that lets users easily manage everything from security systems to thermostats. Our technology made it more intuitive for customers to control their homes from anywhere.'
            },
            {
                title: 'Improving System Reliability',
                body: 'Our engineers helped boost the reliability of the system, ensuring that users could count on real-time alerts and smooth communication between devices. This increased trust in the platform and improved user experience.'
            },
            {
                title: 'Supporting Seamless Integration',
                body: 'We made it easier for the platform to integrate with a wide range of devices and services, giving users more options to customize their smart home setup. This flexibility allowed the company to better meet diverse customer needs.'
            }
        ]
    },
    timeToEat: {
        title: 'Reducing Food Waste with an Easy-to-Use App',
        description: 'We developed a platform that helps people find local restaurants and stores offering surplus food at discounted prices. By making it simple and accessible, this app encourages sustainable eating while giving users access to affordable meals and supporting local businesses.',
        slug: '/case-studies/reducing-food-waste',
        showSeeMoreButton: false,
        imgSrc: '/time-to-eat-mock-crop.jpg',
        imgAlt: 'Reducing Food Waste with an Easy-to-Use App',
        accordionList: [
            {
                title: 'Helping Cut Down on Food Waste',
                body: 'We developed an effective app that connects people with local restaurants and stores offering surplus food at discounted prices. It’s a win-win: less food goes to waste, and customers get great deals on meals.'
            },
            {
                title: 'Making the App Easy to Use',
                body: 'We focused on creating a super user-friendly experience. From browsing nearby food options to picking up orders, the app is designed to make the whole process quick and hassle-free.'
            },
            {
                title: 'Encouraging Sustainability in Local Communities',
                body: 'Our platform helps local businesses reduce waste and engage with their communities. By using the app, customers are supporting sustainability efforts while also enjoying affordable food.'
            }
        ]
    },
    coopCh: {
        title: 'Building a Full-Feature Frontend for One of Switzerland’s Largest Supermarkets',
        description: 'We developed the complete frontend for one of Switzerland’s top supermarket chains, transforming their limited online store into a fully-featured, high-performance platform.',
        slug: '/case-studies/swiss-supermarket-frontend',
        showSeeMoreButton: false,
        imgSrc: '/swiss-supermarket.jpg',
        imgAlt: 'On demand service platform',
        accordionList: [
            {
                title: 'Enhancing the User Experience',
                body: 'We transformed the online shopping experience by creating a user-friendly interface that simplifies product discovery. This intuitive design encourages customer engagement and helps shoppers find what they need quickly and efficiently.'
            },
            {
                title: 'Building a Feature-Rich Platform',
                body: 'Our team developed a comprehensive frontend that includes advanced features like personalized recommendations, easy navigation, and a streamlined checkout process.'
            },
            {
                title: 'Ensuring Robust Compatibility',
                body: 'We focused on delivering a seamless experience across all major browsers, including older versions like IE11. By eliminating compatibility issues, we ensured that every customer could access the platform without barriers.'
            }
        ]
    },
    coopBrands: {
        title: 'Frontend Development for Swiss Supermarket\'s Diverse Brand Ecosystem',
        description: 'We partnered with a major retail group to boost the online presence of multiple brands in their portfolio. Our team delivered optimized, user-friendly frontends for each brand, enhancing user experience and engagement across all digital platforms.',
        slug: '/case-studies/swiss-supermarket-brands',
        showSeeMoreButton: false,
        imgSrc: '/swiss-supermarket-brands.jpg',
        imgAlt: 'Swiss Supermarket Brands',
        accordionList: [
            {
                title: 'Tailored User Experiences for Varied Audiences',
                body: 'We developed distinct, engaging interfaces for each brand. By aligning with target audiences and product offerings, we created experiences that resonate with customers and build brand loyalty.'
            },
            {
                title: 'Streamlined Navigation and Enhanced Usability',
                body: 'Usability was a top priority, enabling customers to navigate extensive product catalogs effortlessly. With clear, intuitive layouts and strong calls-to-action, we crafted a smooth shopping experience that encourages exploration and satisfaction.'
            },
            {
                title: 'Optimized Performance and Cross-Brand Compatibility',
                body: 'We optimized performance to ensure fast loading and smooth functionality across devices and browsers. By addressing compatibility, even with older browsers, we ensured that every customer could engage with the platforms seamlessly.'
            }
        ]
    }
}


export default caseStudy;
