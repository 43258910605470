import React, {useEffect} from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SecondaryPageHeader from "../components/SecondaryPageHeader"
import NewCaseStudyContainer from "../components/NewCaseStudyContainer";
import NewCaseStudyDetails, {CaseStudyStyle} from "../components/NewCaseStudyDetails";
import caseStudy from "../content/case-study";
import HowCanWeHelpBanner from "../components/Index/HowCanWeHelpBanner";

const Contact = () => {

  useEffect(() => {
    document.body.classList.add('inverse');
    return () => {
      document.body.classList.remove('inverse');
    }
  }, []);

  return (
    <Layout>
      <div className="contact body-content">
        <Navbar className="navbar-fixed-top" inverse={true}/>
        <SecondaryPageHeader smallTitle='Our Work' title='Our portfolio of digital products and services'
                             subtext="We've completed over 40 projects accross multiple industries in client work.
                             Successfully collaborated with more than 15 partners from countries in the EU and USA.
                             Below are some of the projects we're able to share with you."/>

        <NewCaseStudyContainer>
          <NewCaseStudyDetails style={CaseStudyStyle.RIGHT} content={caseStudy.solarBlocks}/>
          <NewCaseStudyDetails style={CaseStudyStyle.LEFT} content={caseStudy.enhancedTravel}/>
          <NewCaseStudyDetails style={CaseStudyStyle.RIGHT} content={caseStudy.fsm}/>
          <NewCaseStudyDetails style={CaseStudyStyle.LEFT} content={caseStudy.emila}/>
          <NewCaseStudyDetails style={CaseStudyStyle.RIGHT} content={caseStudy.iControl}/>
          <NewCaseStudyDetails style={CaseStudyStyle.LEFT} content={caseStudy.timeToEat}/>
          <NewCaseStudyDetails style={CaseStudyStyle.RIGHT} content={caseStudy.coopCh}/>
          <NewCaseStudyDetails style={CaseStudyStyle.LEFT} content={caseStudy.coopBrands}/>
        </NewCaseStudyContainer>
        <HowCanWeHelpBanner message={"Need a development partner?"} url={"/contact"} ctaMessage={"lets get started"}/>
        <Footer/>
      </div>

    </Layout>
  );
}

export default Contact
